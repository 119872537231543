import "./CoffeeChat.css";

export const CoffeeChat = () => {

  return (<div className="coffeeChatWrapper">
    <div class="coffeeChatContent">
      <div class="twoCol">
        <div className="left">
          <h1>Archive</h1>
          <ul>
            <li><a href="https://sh1.sendinblue.com/vh3q4v3wlpfe.html?t=1732407772253">Vol 2 | Nov 24 2024</a></li>
            <li><a href="https://sh1.sendinblue.com/vcw0sv3wlpfe.html?t=1732405882545">Vol 1 | Nov 9 2024</a></li>
          </ul>
        </div>
        <div className="right">
          <p className="phrase">with love and tight hugs,</p>
          <p className="signature">Hailey</p>
        </div>
      </div>
    </div>
  </div>);
};